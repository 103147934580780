$font-family-sans-serif:              Helvetica, "Helvetica Neue", Arial, sans-serif;
$headings-font-family:                Impact, Anton, Helvetica, "Helvetica Neue", Arial, sans-serif;

$font-size-base:                      17px;
$font-size-large:                     18px;
$font-size-xl:                        26px;
$font-size-giant:                     35px;
$font-size-h1:                        45px;
$font-size-h2:                        30px;
$font-size-h3:                        30px;
$font-size-h4:                        25px;

$btn-default-bg:                      #000;
$btn-default-color:                   #fff;
$btn-default-border:                  #000;

$btn-primary-bg:                      #fff;
$btn-primary-color:                   #000;

$navbar-border-radius:                0;
$navbar-margin-bottom:                0;

$navbar-default-border:               none;
$navbar-default-link-active-bg:       transparent;
$navbar-default-toggle-hover-bg:      transparent;
$navbar-default-toggle-icon-bar-bg:   #fff;
$navbar-default-toggle-border-color:  #fff;

$nav-link-hover-bg:                   none;

@import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
